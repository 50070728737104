export const InvoicePaymentStatuses = Object.freeze({
  DRAFT: 0,
  APPROVED: 1,
  PAID: 2
})

export const InvoicePaymentStatusTexts = Object.fromEntries(
  Object.entries(InvoicePaymentStatuses).map(a => a.reverse())
)

export const InvoiceItemsStatuses = Object.freeze({
  REGULAR: 0,
  DEDUCTION: 1,
  DISCOUNT: 2
})

export const InvoiceItemsStatusTexts = Object.fromEntries(
  Object.entries(InvoiceItemsStatuses).map(a => a.reverse())
)


export const InvoiceTypes = Object.freeze({
  CARD_TRANSACTIONS: 0,
  BANK_REPORT_TRANSACTIONS: 1,
  COMPANY_INVOICE: 2
})

export const CommonStatuses = Object.freeze({
  QUEUED: 0,
  IN_PROGRESS: 1,
  DONE: 2,
  ERROR: 3
})

export const ProductStatuses = Object.freeze({
  STATUS_NEW: 0,
  STATUS_PROCESSING: 1,
  STATUS_APPROVED: 2,
  STATUS_REJECTED: 3,
  STATUS_DELETING: 4
})

export const CommonStatusTexts = Object.fromEntries(
  Object.entries(CommonStatuses).map(a => a.reverse())
)

export const BuyerRoleName = "BUYER"

export const MonthyReportCategories = Object.freeze({
  bff: "Banking and Finance",
  fspf: "Financial Services and Payments"
})
